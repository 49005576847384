<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            codeGroupCd="SOP_PRE_WORK_CHECK_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            name="sopPreWorkCheckClassCd"
            label="종류"
            v-model="searchParam.sopPreWorkCheckClassCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="작업 전 안전점검 구분 목록"
      :columns="grid.columns"
      :data="grid.data"
      :filtering="false"
      :columnSetting="false"
      :editable="editable"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            :showLoading="false" 
            label="추가" 
            icon="add" 
            @btnClicked="addPreWorkCheckClass" />
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="저장" 
            icon="save" 
            @beforeAction="savePreWorkCheckClass"
            @btnCallback="savePreWorkCheckClassCallback"/>
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'preWorkCheckItem',
  data() {
    return {
      searchParam: {
        sopPreWorkCheckClassCd: null,
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'sopPreWorkCheckClassCd',
            field: 'sopPreWorkCheckClassCd',
            label: '종류',
            align: 'center',
            sortable: true,
            required: true,
            style: 'width:100px',
            type: 'select',
            itemText: 'codeName',
            itemValue: 'code',
            codeGroupCd: 'SOP_PRE_WORK_CHECK_CLASS_CD',
          },
          {
            name: 'checkClassName',
            field: 'checkClassName',
            label: '점검구분명',
            align: 'left',
            sortable: true,
            required: true,
            style: 'width:400px',
            type: 'text',
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            sortable: true,
            style: 'width:100px',
            type: 'check',
            true: 'Y',
            false: 'N',
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            type: 'number',
          },
        ],
        data: [],
      },
      useFlagItems: [],
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.pwc.class.list.url
      this.saveUrl = transactionConfig.sop.pwc.class.save.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addPreWorkCheckClass() {
      if (!this.grid.data) {
        this.grid.data = [];
      }
      this.grid.data.splice(0, 0, {
        sopPreWorkCheckClassId: '',  // 작업 전 안전점검 구분 일련번호
        sopPreWorkCheckClassCd: null,  // 작업 전 안전점검 구분 코드
        checkClassName: '',  // 점검 구분명
        useFlag: 'Y',  // 사용여부
        sortOrder: 0,  // 순번
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      })
    },
    savePreWorkCheckClass() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    savePreWorkCheckClassCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
